// Initialize the entire document body on load
// Any reactive content will also need to call this method
initializeRoot();

/**
 * Searches through the given element's descendants and initializes any Game Item elements
 * @param {HTMLElement} element
 */
export function initializeRoot(element = document.documentElement) {

    const gameItems = element.querySelectorAll('.gff-game-item,.gff-game-item-jpj,.gff-game-item--legacy');

    gameItems.forEach((gameItem) => {
        initializeItem(gameItem);
    });
}

/**
 * Initializes the given element's anchor tag descendants
 *
 * Initialized anchor tags will redirect to their own href values, but with analytics parameters appended as query parameters
 * @param {HTMLElement} element
 */
export function initializeItem(element) {

    // Find a parent element which contains the 'data-resource' attribute
    // This contains the path of the current filter, which will be used as the launchOrigin value
    const resourceContainer = element.closest('[data-resource]:not([data-resource=""])');

    const launchOrigin = resourceContainer !== null ? resourceContainer.getAttribute('data-resource') : 'filter';
    const launchOriginUrl = top.location.pathname;
    const position = Array.from(element.parentNode.children).indexOf(element);

    const anchors = element.querySelectorAll('a');
    anchors.forEach((anchor) => {

        // Construct the analytics path of the current anchor tag
        let path = anchor.href + '?launchOrigin=' + launchOrigin + '&launchOriginUrl=' + launchOriginUrl + '&position=' + position;

        // Subscribe any clicks made on the current anchor tag to the redirect function
        // Clears any previous subscriptions in case the item has previously been processed, to avoid duplicate event listeners
        anchor.removeEventListener('click', redirect.bind(null, path));
        anchor.addEventListener('click', redirect.bind(null, path));
    });
}

/**
 * Redirects the top window to the given path
 * @param {String} path
 * @param {Event} event
 */
function redirect(path, event) {

    // Prevent the default event from firing
    // This makes sure that any anchor tags don't override the following redirect
    event.preventDefault();

    top.location.href = path;
}

window.GameAnalytics = {
    initializeRoot,
    initializeItem
};
